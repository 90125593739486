import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { HomeHeader, Banner, BannerButton } from '../utils'
import img from '../images/boat1.jpg'
import QuickInfo from '../components/HomePageComponents/QuickInfo'
// import Gallery from '../components/HomePageComponents/Gallery-1'
import MobileBanner from '../components/HomePageComponents/HomeMobileBanner'
import styled from 'styled-components'

const IndexPage = () => (
  <Layout>
    <SEO
      title='S.A. Walley Marine Surveying'
      keywords={[
        `Marine Surveying`,
        `Gulf Coast`,
        `Walley`,
        `Pensacola`,
        `Florida`,
        `Orange Beach`,
        `Gulf Shores`,
        `Alabama`,
        `Perdido Key`,
        `Northwest Florida`,
        `Mississippi`,
        `Louisiana`,
        `Tennessee`,
        `Constulting`,
        `Delivery`,
        `Vessel`,
        `Boat`,
        `Evaluation`,
        `Insurance Claim`,
        `Valuation`
      ]}
    />
    <LargeBanner>
      <HomeHeader img={img}>
        <Banner
          title='S.A. Walley Marine Services'
          subtitle='Marine Surveying & Consultation'
        >
          <Link to='/contact' style={{ textDecoration: 'none' }}>
            <BannerButton style={{ margin: '2rem auto' }}>Contact</BannerButton>
          </Link>
        </Banner>
      </HomeHeader>
    </LargeBanner>
    <MobileBanner />
    <QuickInfo />
  </Layout>
)

export default IndexPage

const LargeBanner = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`
