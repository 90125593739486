import React, { Component } from 'react'
import { Section, Title, SectionButton } from '../../utils'
import styled from 'styled-components'
import { Link } from 'gatsby'
import img1 from '../../images/img1.jpg'
import img2 from '../../images/img2.jpg'

// import { styles } from '../../utils'
export default class QuickInfo extends Component {
  render() {
    return (
      <Section>
        <Title
          message='Professional. Experienced. Accurate.'
          title='Serving the Gulf Coast'
        />
        <QuickInfoWrapper>
          <center>
            <p className='text'>
              My goal in the marine surveying business is to serve my clients to
              the best of my ability. Whether it’s a pre-purchase situation or
              an insurance renewal, I want them to have the confidence that
              their vessel is prepared to serve them in whatever manor that they
              wish.
              <br />
              -Scott Walley
            </p>
          </center>
          <Link to='/services/' style={{ textDecoration: 'none' }}>
            <SectionButton style={{ margin: '2rem auto' }}>
              Services
            </SectionButton>
          </Link>
          <div className='row'>
            <div className='col-md-6'>
              <img src={img1} alt='' />
            </div>
            <div className='col-md-6'>
              <img src={img2} alt='' />
            </div>
          </div>
        </QuickInfoWrapper>
      </Section>
    )
  }
}

const QuickInfoWrapper = styled.div`
  width: 90%; //of 90vw
  margin: 2rem auto;
  img {
    padding: 0.5rem 0.5rem;
    border-radius: 3%;
  }
  .text {
    line-height: 2rem;
    word-spacing: 0.1rem;
  }
  @media (min-wdith: 768px) {
    width: 70%;
  }
  @media (min-wdith: 992px) {
    width: 60%;
  }
`
