import React, { Component } from 'react'
import img from '../../images/boat1.jpg'
import styled from 'styled-components'
import { styles } from '../../utils'

export default class HomeMobileBanner extends Component {
  render() {
    return (
      <MobileHeader>
        <img src={img} alt='Walley Marine Services Vessel' />
        <center>
          <h1>S.A. Walley Marine Services</h1>
          <h3>Marine Surveying & Consultation</h3>
          <hr />
        </center>
      </MobileHeader>
    )
  }
}

const MobileHeader = styled.div`
  hr {
    width: 80%;
  }
  h1 {
    margin: 1rem 0 0 0;
    font-weight: 600;
    font-size: 26px;
    color: ${styles.colors.Navy};
    font-family: 'Roboto', sans-serif !important;
  }

  h3 {
    font-weight: 400;
    font-size: 18px;
    color: ${styles.colors.Gold};
    font-family: 'Roboto', sans-serif;
    margin-top: 1rem;
  }
  @media (min-width: 567px) {
    display: none;
  }

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`
